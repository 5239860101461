.Header {
  display: flex;
  gap: var(--size-space-4);
  align-items: center;
  align-self: stretch;
  max-width: 1584px;
  min-height: 88px;
  padding: var(--size-space-5) 32px var(--size-space-4) 32px;
  background: var(--color-layer-primary, #ffffff);
  border-bottom: var(--size-border-1) solid var(--color-border-light);
}

.Title {
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
