.Content {
  flex: 1;
  padding: var(--size-space-6);
  background-color: var(--color-border-light);
}

.Right {
  flex: 1;
  padding: var(--size-space-6);
  background-color: #ffffff;
  border: 1px solid var(--color-border-light);
  border-radius: var(--size-radius-4);
}

.GridContainer {
  display: flex;
  gap: 32px;
}

.ListTone {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-space-2);
  margin-bottom: var(--size-space-4);
}

.ToneButtonOff {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: var(-color-field-disabled);
  border: none;
  border-radius: var(--size-radius-max);
  transition: 0.3s ease box-shadow;

  svg {
    flex-shrink: 0;
  }

  &.isActive {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-border-focus);
  }
}

.ToneButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  border-radius: var(--size-radius-max);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-border-focus);
  }

  &.isActive {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-border-focus);
  }
}

.ToneButtonCircle {
  display: block;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
}

.ToneButtonImg {
  object-fit: none;
  width: 40px;
  height: 40px;
}

.RandomListTitle {
  margin: var(--size-space-6) 0 var(--size-space-4);
}

.RandomList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--size-space-6);
}

.RandomListItem {
  border: 1px solid var(--color-border-light, #e1e2e3);
  border-radius: var(--size-radius-4, 16px);
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .GridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 672px) and (max-width: 1024px) {
  .RandomList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 320px) and (max-width: 672px) {
  .RandomList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
