.GoodCard {
  padding: var(--size-space-4);
  cursor: pointer;
  background-color: var(--color-layer-primary);
  border-radius: var(--size-space-4);
  transition: 0.3s ease box-shadow;

  &:hover {
    box-shadow: 0 8px 24px 0 rgba(139, 141, 143, 0.16);
  }
}

.GoodCardContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-space-4);
  align-items: center;
  font-size: var(--font-body-1-font-size);
  color: var(--color-text-secondary);
}

.GoodCardImageSmall {
  display: block;
  width: 100%;
  max-width: 10.5rem;
  height: 10.5rem;
  object-fit: contain;
}

.GoodCardImageMedium {
  display: block;
  width: 100%;
  max-width: 16rem;
  height: 16rem;
  object-fit: contain;
}
