.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.ModalWrapper {
  width: 38rem;
  background-color: white;
  border-radius: var(--size-space-4);
}

.FormWrapper > div {
  gap: 0;
}

.ModalHeader {
  display: flex;
  justify-content: space-between;
  padding: var(--size-space-5) var(--size-space-5) var(--size-space-4);
  font-size: 1.5rem;
  border-bottom: var(--size-border-1) solid var(--color-border-light);
}

.ModalContent {
  display: flex;
  flex-flow: row wrap;
  gap: var(--size-space-4);
  padding: var(--size-space-4) var(--size-space-5);
}

.ModalField {
  width: 17rem;
}

.ModalFooter {
  display: flex;
  gap: var(--size-space-4);
  justify-content: flex-end;
  padding: var(--size-space-4) var(--size-space-5) var(--size-space-5);
  border-top: var(--size-border-1) solid var(--color-border-light);
}

.ModalFooterButton {
  padding: 0 var(--size-space-5);
}

.Notification {
  position: fixed;
  right: var(--size-space-4);
  bottom: var(--size-space-4);
  width: 27.5rem;
}
