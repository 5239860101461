.CategoryCard {
  padding: var(--size-space-4);
  cursor: pointer;
  background-color: var(--color-layer-primary);
  border-radius: var(--size-space-4);

  &:hover {
    box-shadow: 0 8px 24px 0 rgba(139, 141, 143, 0.16);
  }
}

.CategoryCardContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-space-4);
}

.CategoryCardImage {
  display: block;
  width: 100%;
  height: 16rem;
  object-fit: contain;
}
