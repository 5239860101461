.Content {
  flex: 1;
  padding: var(--size-space-6);
  background-color: var(--color-border-light);
}

.List {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--size-space-6);
}

@media screen and (min-width: 672px) and (max-width: 1024px) {
  .List {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 320px) and (max-width: 672px) {
  .List {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    div:last-child {
      grid-column: span 1;
    }
  }
}
