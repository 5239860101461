.Container {
  position: relative;
  flex: 1;
  flex-shrink: 0;
  max-width: 447px;
  height: 584px;
  overflow: hidden;
  border: 1px solid var(--color-border-light);
  border-radius: var(--size-radius-4);
}

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--color-field);
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .Container {
    max-width: 100%;
  }
}
