.Content {
  flex: 1;
  padding: var(--size-space-6);
  background-color: var(--color-border-light);
}

.MainBlock {
  margin-bottom: var(--size-space-6);
}

.Title {
  margin-bottom: var(--size-space-4);
}

.Description {
  margin-bottom: var(--size-space-6);
}

.Categories {
  margin-bottom: var(--size-space-6);
}

.CategoriesTitle,
.ProductsTitle {
  margin-bottom: var(--size-space-4);
}

.CategoriesList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--size-space-6);
}

.ProductList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--size-space-6);
}

.Picture {
  width: 608px;
  height: 360px;
  background-image: url("../../../assets/mainImg.png");
  background-position: center;
}

@media screen and (min-width: 672px) and (max-width: 1024px) {
  .CategoriesList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div:last-child {
      grid-column: span 2;
    }
  }

  .ProductList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-space-6);
  }

  .Picture {
    width: 288px;
    height: 360px;
    background-image: url("../../../assets/mainimg_adaptive.png");
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 320px) and (max-width: 672px) {
  .CategoriesList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    div:last-child {
      grid-column: span 1;
    }
  }

  .ProductList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--size-space-6);
  }

  .Picture {
    display: none;
  }
}
