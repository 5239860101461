@use "node_modules/normalize.css/normalize.css";
@use "@smkt-web/rampa/styles/index.scss";

* {
  box-sizing: border-box;
  margin: 0;
}

img {
  display: block;
}
